import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router'
import {useNavigate} from 'react-router-dom'
import { Box, Card, Grid, Skeleton } from '@mui/material'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import { useAuth } from '../../../contexts/AuthContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import '../aggregate.scss'
import AccountInfo from '../components/AccountInfo'
import AggregateTitle from '../components/AggregateTitle'
import AllocationChart from '../components/AllocationChart'
import PortfolioTable from '../components/PortfolioTable'
import GroupCard from '../components/GroupCard'
import RestrictionGroupTable from '../components/RestrictionGroupTable'

const IPSAggregate = () => {
  const params = useParams()
  const { user } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const { showError } = useErrorToast()
  const familyId = params.familyId
  const [isLoading, setIsLoading] = useState(true)
  const [isGroupCardLoading, setIsGroupCardLoading] = useState(true)
  const [isGroupRestrictionLoading, setIsGroupRestrictionLoading] = useState(true)
  const [selectedAggregate, setSelectedAggregate] = useState({aggName: '', aggCode: ''})
  const [aggregateData, setAggregateData] = useState({'Total Market Value': '', 'Cash': '', 'Unrealized Gain/Loss': '', 'Realized Gain/Loss': ''})
  const [aggregateAccountData, setAggregateAccountData] = useState([])
  const [aggregateGroupData, setAggregateGroupData] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({ index: 0, type: 'IPS', id: '', name: ''}) // working with 1 based indexing
  const [selectedGroupRestrictionData, setSelectedGroupRestrictionData] = useState([])

  const colors = ['#2A96CE', '#009445', '#1C75BC', '#002A59', '#6A86A6', '#F7941E', '#F15A2B', '#D7F422', '#7B5231', '#2962FF']

  const formatAggregateData = (aggData) => {
    setAggregateData({
      'Total Market Value': aggData?.totalMv,
      'Cash': aggData?.cash,
      'Unrealized Gain/Loss': aggData?.urgl,
      'Realized Gain/Loss': aggData?.rgl
    })
    setSelectedAggregate({aggName: aggData?.aggName ? aggData?.aggName : '', aggCode: aggData?.aggCode ? aggData?.aggCode : ''})
    setSelectedGroup(prev => ({...prev, name: aggData?.aggName}))
  }

  const fetchPortfolioAccounts = async (id) => {
    setIsLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/aggregate-portfolio/${id ? id : familyId}`
    )
      .then((response) => {
        if (response?.data?.accountData) {
          setAggregateAccountData(response?.data?.accountData)
        }
        formatAggregateData(response?.data?.agg)
        setIsLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setIsLoading(false)
      })
  }

  const fetchGroupRestriction = async (id) => {
    setIsGroupRestrictionLoading(true)
    API.get(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/aggregate/group-restrictions/${id}`
    )
      .then((response) => {
        if (response?.data) {
          setSelectedGroupRestrictionData(response?.data)
        }
        setIsGroupRestrictionLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setIsGroupRestrictionLoading(false)
      })
  }

  const fetchAggregateGrouping = async (id, type) => {
    setIsGroupCardLoading(true)
    API.post(
      'baseUriAggregateMaster',
      `aggregate-master/v1/${user.userGroup}/aggregate-grouping`,
      { body: { aggId: id, aggType: type } }
    )
      .then((response) => {
        if (response?.data) {
          setAggregateGroupData(response?.data)
        }
        setIsGroupCardLoading(false)
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
        setIsGroupCardLoading(false)
      })
  }

  const handleSelectedGroupIndexCallback = (newGroup) => {
    // fetch portfolio data when user selects IPS group card
    if (user && newGroup.type === 'IPS' && newGroup.index !== 0) {
      setIsLoading(true)
      setIsGroupCardLoading(true)
      const url = location.pathname.replace(params.familyId, newGroup.id)
      navigate(url)
    }

    // fetch restriction only when selected group index is not 0 and type of selected group is not IPS 
    if (user && newGroup.index !== 0 && newGroup.type !== 'IPS') {
      fetchAggregateGrouping(newGroup.id, newGroup.type)
      fetchGroupRestriction(newGroup.id)
    }
    setSelectedGroup(newGroup)
  }

  useEffect(() => {
    // used for browser page back and forward navigation
    // and user has selected other IPS card
    if(user) {
      setSelectedGroup({ index: 0, type: 'IPS', id: '', name: '' })
      setIsGroupCardLoading(true)
      setIsLoading(true)
      fetchPortfolioAccounts()
      fetchAggregateGrouping(params.familyId, 'IPS')
    }
  }, [location])

  return (
    <>
      <Box className='aggregate-page'>
        <Grid container>
            <Grid xs={10} item>
              <AggregateTitle title={selectedGroup.type} name={selectedGroup.name || selectedAggregate.aggName} number={selectedAggregate.aggCode} accountCodeShow={selectedGroup.type === 'IPS'} loading={isLoading}  />
            </Grid>
            <Grid item xs={12} sm={9} pr={3} mt={1}>
              {
                selectedGroup.type !== 'IPS'
                ? <Grid item sm={12}>
                    <Card>
                      <Box sx={{ position: 'relative', px: '20px', py: '20px', height: 'calc(100vh - 80px - 8px - 72px - 8px)', overflowY: 'auto', scrollbarGutter: 'stable' }}>
                        <RestrictionGroupTable data={selectedGroupRestrictionData} loading={isGroupRestrictionLoading} />
                      </Box>
                    </Card>
                  </Grid>
                : <>
                    <Grid item sm={12}>
                      {aggregateData && !isLoading ? <AccountInfo headers={Object.keys(aggregateData)} values={Object.values(aggregateData)} /> : <AccountInfo headers={Object.keys(aggregateData)} values={[]} />}
                    </Grid>
                    <Grid item sm={12} mt={3}>
                      <Grid item sm={12} mt={3}>
                        <Card>
                          <Box sx={{ position: 'relative', px: '20px', py: '20px', height: 'calc(100vh - 80px - 8px - 72px - 24px - 90px - 24px )', overflowY: 'auto', scrollbarGutter: 'stable' }}>
                            <AllocationChart accountAllocationData={aggregateAccountData} loading={isLoading} />
                            <PortfolioTable data={aggregateAccountData} loading={isLoading} />
                          </Box>
                        </Card>
                      </Grid>
                    </Grid>
                  </>
              }
            </Grid>
            {
              <Grid item xs={12} sm={3} mt={1} sx={{ backgroundColor: 'none' }}>
                <Box sx={{ height: 'calc(100vh - 80px - 8px - 72px - 24px)', overflowY: 'auto', scrollbarGutter: 'stable', scrollbarWidth: 'none', pr: '1px' }}>
                  {
                    isGroupCardLoading
                    ? Array.from({ length: 5 }).map((_, i) => (
                      <Grid item sm={12} key={i} mb={2}>
                        <Skeleton
                          variant='rounded'
                          sx={{ height: '70px' }}
                        />
                      </Grid>
                    ))
                    : aggregateGroupData?.map((data, index) => (
                      <Grid item sm={12} key={index} pt={'1px'}>
                        {selectedGroup.index === 0
                          ? data.groupCode !== 'IPS'
                            ? <GroupCard {...data} index={index + 1} setSelectedGroup={handleSelectedGroupIndexCallback} borderColor={colors[(index) % (colors.length)]} />
                            : ''
                          : <GroupCard {...data} index={index + 1} setSelectedGroup={handleSelectedGroupIndexCallback} borderColor={colors[(index) % (colors.length)]} />
                        }
                      </Grid>
                    ))
                  }
                </Box>
              </Grid>
            }
          </Grid>
      </Box>
    </>
  )
}

export default IPSAggregate