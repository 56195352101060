import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { API } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { Box, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { useAuth } from '../contexts/AuthContext'
import { moduleConfig } from '../contexts/data'
import { useErrorToast } from '../hooks/useErrorToast'
import { ACCESS_LEVEL } from '../contstants/constants'
import PolicyStatusPopup from './PolicyStatusPopup'

const AccountTitle = ({ title, children }) => {
  const { user, checkAccess } = useAuth()
  const params = useParams()
  const location = useLocation()
  const { showError } = useErrorToast()
  const [custodianAccountNumber, setCustodianAccountNumber] = useState('')
  const [showPolicyPopup, setShowPolicyPopup] = useState(false)
  const [policyData, setPolicyData] = useState({})

  const datasecure = localStorage.getItem('object')
  const getStoreData = JSON.parse(datasecure)
  const filterSecuredata = getStoreData?.filter(
    (item) => item?.accountId === params?.accountId
  ) || []

  const fetchAccountPolicy = (id) => {
    API.post('baseDataValidation', `data-validation/v1/${user.userGroup}/policies/accounts`, {
      body: {
        accountIds: [id]
      }
    })
      .then(res => {
        if (res?.data?.length) {
          setPolicyData(res?.data[0])
        }
      })
      .catch((error) => {
        showError(error.response?.data?.errorInfo?.userMessage || error.message)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      })
  }

  useEffect(() => {
    if (user) {
      if (title === 'Dashboard' && checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.ACCOUNT_DASHBOARD, component_name: moduleConfig.VIEW_ACCOUNT_POLICY_CHECK }))
        fetchAccountPolicy(params?.accountId)
    }
  }, [user, params?.accountId])

  useEffect(() => {
    if (filterSecuredata && filterSecuredata[0] && filterSecuredata[0]?.custodianAccountNumber && filterSecuredata[0]?.accountType === 'TRADING') {
      setCustodianAccountNumber(` - ${filterSecuredata[0]?.custodianAccountNumber}`)
    } else {
      setCustodianAccountNumber('')
    }
  }, [filterSecuredata])

  const getStatusColor = (status) => {
    if (status === 'green')
      return '#3BBFA3'
    else if (status === 'red')
      return '#FF6161'
    else if (status === 'yellow')
      return '#D29922'
    else if (status === 'gray')
      return '#0000004d'
    return '#c9c9c9'
  }

  return (
    <>
      <Grid container justifyContent='space-between' alignItems='center' mb={2}>
        <Grid item display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
          <Typography component='div' className='page-title' sx={{ paddingTop: '0 !important' }}>{title}</Typography>
          <Box className='client' mt={{ xs: 3, md: 0 }}>
            <Box sx={{
              borderRadius: '4px',
              width: '3.05px',
              height: '53.8px',
              mx: 2,
              background: filterSecuredata[0]?.priority === 1 ? '#F05F5F' : filterSecuredata[0]?.priority === 2 ? '#169D6C' : '#EFAE3A'
            }}
            />
            <Box>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='h6'>{filterSecuredata[0]?.accountName}</Typography>
                  <Chip label={filterSecuredata[0]?.accountType} size='small' sx={{ background: '#50CAF1', color: 'white', fontSize: '10px', fontWeight: '500', ml: 1, height: '16px' }} />
                </Box>
                {
                  title === 'Dashboard' && checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.ACCOUNT_DASHBOARD, component_name: moduleConfig.VIEW_ACCOUNT_POLICY_CHECK }) && policyData?.accountPolicyColorStatus ? (
                    <Tooltip title={'Account Policies'}>
                      <IconButton onClick={() => setShowPolicyPopup(true)} sx={{ p: '6px' }}>
                        <CircleIcon sx={{ color: getStatusColor(policyData?.accountPolicyColorStatus), height: '12px', width: '12px' }} />
                      </IconButton>
                    </Tooltip>
                  ) : ''
                }
              </Box>
              <Typography variant='small' lineHeight='1.25rem' fontSize='0.875rem' mr={1}> {filterSecuredata[0]?.accountCode}{custodianAccountNumber || ''}{' -> '}{filterSecuredata[0]?.portSpecName}</Typography>
              <Link to={location.pathname.replace(params.accountId, '').replace('/' + params.RequestId, '')} style={{ color: '#3A76D4' }}>                Switch Account
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
      {title === 'Dashboard' && <PolicyStatusPopup showPolicyPopup={showPolicyPopup} onClose={() => setShowPolicyPopup(false)} data={policyData} canDownloadJson={checkAccess(moduleConfig.ACCOUNT_REVIEW, ACCESS_LEVEL.COMPONENT_ACCESS, { subModuleName: moduleConfig.ACCOUNT_DASHBOARD, component_name: moduleConfig.DOWNLOAD_ACCOUNT_POLICY_CHECK })} title='account' />}
    </>
  )
}

export default AccountTitle