export const FundingSource = {
  CASH: 'Cash only',
  SECURITY: 'Security only',
  CASH_SECURITY: 'Cash & Security'
}

export const TaxSensitivity = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low'
}

export const WithdrawalFrequency = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarter',
  HALF_YEAR: 'Half year',
  YEARLY: 'Annual'
}

export const RequestStratagy = {
  GENERAL: 'General Request',
  CLOSE_ACCOUNT: 'Close Account',
  GENERATE_PROPOSAL: 'Generate Proposal',
  CASH_RAISE: 'Cash Raise',
  YTD_EXTERNAL_GAIN_LOSS: 'YTD External Gain/Loss '
}

export const ActiveStatusCode = {
  0: 'Close',
  1: 'Active',
  2: 'Pending',
  3: 'Locked'
}
export const RequestStatus = {
  PENDING: 'Pending',
  REVIEW: 'Review',
  FULFILLED: 'Fulfilled',
  CANCELLED: 'Cancelled'

}

export const tradeStatusCodes = {
  TRADE_IN_PROGRESS: 'In Progress',
  TRADE_COMPLETED: 'Completed',
  TRADE_BREAK: 'Break',
  TRADE_FAILED: 'Failed'
}

export const scenarioSensitivity = {
  HIGH: 'Min Tax',
  MEDIUM: 'Balanced',
  LOW: 'Model'
}

export const roles = {
  pm: 'Portfolio Manager',
  'adv-classic': 'Advisor Classic',
  admin: 'Admin'
}

export const ACCESS_LEVEL = {
  MODULE_ACCESS: 'module-access',
  SUB_MODULE_ACCESS: 'sub-module-access',
  PAGE_ACCESS: 'page-access',
  ACTION_ACCESS: 'action-access',
  ROUTE_ACCESS: 'route-access',
  COMPONENT_ACCESS: 'component-access'
}

export const ENV_MAP = {
  development: 'DEV',
  staging: 'STG',
  production: 'PRD'
}